import React from 'react'
import { css } from '@emotion/react'

import { root, container, copy } from './About'
import Title from '../Title'

import Color from '../../styles/Color'
import Size from '../../styles/Size'

import { max959, min960, min1980 } from '../../styles/Mixin'

const contentsVisions = css`
  ${min960} {
    display: flex;
    justify-content: space-between;
  }
`

const contentsVision = css`
  ${min960} {
    width: 45%;
  }
  & + & {
    ${max959} {
      margin-top: ${Size(16)};
    }
  }
`

const Vision = props => {
  const vision = css`
    ${root}
    background: ${Color.Blue100};
    ${min1980} {
      padding-top: 32rem;
    }
  `

  return (
    <div css={vision}>
      <div css={container}>
        <div css={contentsVisions}>
          <section css={contentsVision}>
            <Title text="Mission" subTitle="MtA creationsの使命" />
            <p css={copy}>
            Be natural, Be yourself.<br />
            今までのデジタル不自然をなくす
            </p>
          </section>
          <section css={contentsVision}>
            <Title text="Vision" subTitle="私たちが目指すビジョン" />
            <p css={copy}>
              デジタル不自然の第一人者として<br />
              デジタル社会を自然な形に開発し続ける
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Vision
