import React from 'react'
import { css } from '@emotion/react'

import Title from './Title'

import Color from '../styles/Color'
import Size from '../styles/Size'
import Typography from '../styles/Typography'

import { max959, min960, min1440 } from '../styles/Mixin'

const root = css`
  position: relative;
  background: ${Color.Blue100};
  ${max959} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(20)};
  }
  ${min960} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(40)};
  }
`

const wrapper = css`
  position: relative;
  width: 100%;
  margin: auto;
  ${min1440} {
    max-width: ${Size(360)};
  }
`

const container = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 ${Size(4)};
  margin: auto;
  ${min960} {
    width: ${Size(240)};
  }
`

const contents = css``

const items = css`
  ${max959} {
    margin-top: ${Size(20)};
  }
  ${min960} {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: ${Size(18)} 0;
  }
`

const item = css`
  ${min960} {
    width: 33%;
    &:nth-of-type(n + 4) {
      margin-top: ${Size(8)};
    }
  }

  & + & {
    ${max959} {
      margin-top: ${Size(6)};
    }
  }
`

const itemTitle = css`
  ${Typography.Body1}
`

const itemText = css`
  ${Typography.Body2}
  ${min960} {
    margin-top: ${Size(2)};
  }
`

const Company = props => {
  return (
    <div css={root} id="company">
      <div css={wrapper}>
        <div css={container}>
          <div css={contents}>
            <Title text="Company" subTitle="会社概要" />
            <div css={items}>
              <dl css={item}>
                <dt css={itemTitle}>会社名</dt>
                <dd css={itemText}>
                  MtA creations株式会社
                  <br />
                  MtA creations Co., Ltd（en）
                </dd>
              </dl>
              <dl css={item}>
                <dt css={itemTitle}>所在地</dt>
                <dd css={itemText}>
                  〒600-8815
                  <br />
                  京都府京都市下京区中堂寺粟田町93
                  <br />
                  KRP 4号館3階 KRP BIZ NEXT
                </dd>
              </dl>
              <dl css={item}>
                <dt css={itemTitle}>事業内容</dt>
                <dd css={itemText}>
                  ・Webサイトの企画、制作、運営
                  <br />
                  ・アプリケーション開発
                  <br />
                  ・デバイス開発（IoT企画、制作、運営）
                </dd>
              </dl>
              <dl css={item}>
                <dt css={itemTitle}>代表者</dt>
                <dd css={itemText}>上田 充憂</dd>
              </dl>
              <dl css={item}>
                <dt css={itemTitle}>設立</dt>
                <dd css={itemText}>2019年10月18日</dd>
              </dl>
              <dl css={item}>
                <dt css={itemTitle}>資本金</dt>
                <dd css={itemText}>
                  100 万円<br />
                  <a href={'/pdf/announcement/20201001.pdf'} target="_blank" rel="noopener noreferrer">第1期 決算公告（PDF形式：94KB／6ページ）</a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Company
