import React from 'react'
import { graphql } from 'gatsby'

// import getUA from '../utils/getUa'
import GlobalStyle from '../styles/GlobalStyle'

import Head from '../components/Head'
import KeyVisual from '../components/KeyVisual'
import Header from '../components/Header'
import About from '../components/About/About'
import Company from '../components/Company'
import Information from '../components/Information'
import Footer from '../components/Footer'

class Top extends React.Component {
  componentDidMount() {
    const WebFont = require('webfontloader')

    WebFont.load({
      typekit: {
        id: 'ytn7cjj',
      },
    })
  }
  render() {
    return (
      <>
        <Head />
        <GlobalStyle />
        <Header />
        <KeyVisual />
        <About />
        <Company />
        <Information news={this.props.data} />
        <Footer />
      </>
    )
  }
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            path
          }
          excerpt
        }
      }
    }
  }
`

export default Top
