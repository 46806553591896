import React from 'react'
import { css } from '@emotion/react'

import Vision from './Vision'
import DigitalUnnatural from './DigitalUnnatural'

import Color from '../../styles/Color'
import Size from '../../styles/Size'
import Typography from '../../styles/Typography'

import { max959, min960, min1440 } from '../../styles/Mixin'

export const root = css`
  position: relative;
  overflow: hidden;
  ${max959} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(20)};
  }
  ${min960} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(40)};
  }
`

export const container = css`
  width: 100%;
  padding-right: ${Size(4)};
  padding-left: ${Size(4)};
  margin: auto;
  ${min1440} {
    max-width: ${Size(360)};
  }
`

export const copy = css`
  position: relative;
  z-index: 1;
  text-align: center;
  ${Typography.Headline1}
  ${max959} {
    margin-top: ${Size(7)};
  }
  ${min960} {
    margin-top: 5vw;
  }
  ${min1440} {
    margin-top: ${Size(20)};
  }
`

export const highlight = css`
  color: ${Color.Blue900};
  font-weight: bold;
`

export const pcNone = css`
  ${min960} {
    display: none;
  }
`

export const mobileNone = css`
  ${max959} {
    display: none;
  }
`

const About = props => {
  return (
    <div id="about">
      <Vision />
      <DigitalUnnatural />
    </div>
  )
}

export default About
