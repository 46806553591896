import React from 'react'
import { css } from '@emotion/react'

import Color from '../styles/Color'
import Size from '../styles/Size'
import Typography from '../styles/Typography'
import { max599, max959, min960, min1440, scaleFont } from '../styles/Mixin'

import logoImage from '../images/logo.png'
import keyvisualPc from '../images/Header/keyvisual_pc.png'
import keyvisualMb from '../images/Header/keyvisual_mb.png'

const root = css`
  position: relative;
  background-color: ${Color.Blue100};
  ${max959} {
    height: 100vh;
  }
  ${min960} {
    height: 57.6vw;
    max-height: 1105px;
  }
`

const keyVisual = css`
  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  img {
    ${max959} {
      height: 100vh;
      object-fit: cover;
      object-position: bottom;
    }
    ${min1440} {
      background-size: 1920px auto;
    }
  }
`

const keyVisualStyle = {
  height: `57.6vw`,
  width: `100%`,
  backgroundColor: `transparent`,
  backgroundSize: `cover`,
  backgroundPosition: `top center`,
  display: `flex`,
  alignItems: `center`,
}

const container = css`
  position: relative;
  z-index: 3;
  padding: 0 ${Size(4)};
  margin: auto;
  ${max959} {
    width: 100%;
    height: 100vh;
  }
  ${min960} {
    width: 960px;
  }
`

const copy = css`
  position: relative;
  line-height: 1;
  text-align: center;
  top: 3vw;
  ${max599} {
    top: 14vh;
    font-size: 3rem;
  }
  ${max959} {
    font-size: 3.9rem;
    ${scaleFont(414, 959, 39, 80)}
  }
  ${min960} {
    font-size: 8rem;
    ${scaleFont(769, 1440, 42, 80)}
  }
`

const logos = css`
  display: flex;
  flex-direction: column;
  ${max959} {
    height: 100vw;
    padding-top: 14vh;
  }
  ${min960} {
    height: 28.8vw;
    align-items: center;
    justify-content: flex-end;
  }
  ${min1440} {
    height: 524px;
  }
`

const logo = css`
  line-height: 0;
  ${max959} {
    max-width: 32vw;
    margin: auto;
  }
  ${min960} {
    max-width: ${Size(42.5)};
    margin: auto;
  }
`

const scroll = css`
  ${Typography.Body1}
  ${max959} {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 17vw;
    transform: rotate(90deg);
  }
  ${min960} {
    display: none;
  }
`

const arrow = css`
  position: absolute;
  right: -30%;
  bottom: 45%;
  display: block;
  width: ${Size(2)};
  height: ${Size(2)};
  border-top: solid ${Size(2 / 5)} ${Color.Black};
  border-right: solid ${Size(2 / 5)} ${Color.Black};
  transform: rotate(45deg) translateY(50%);

  & + & {
    right: -50%;
  }
`

const pcNone = css`
  ${min960} {
    display: none;
  }
`

const mobileNone = css`
  ${max959} {
    display: none;
  }
`

const KeyVisual = props => {
  // const userAgent = props.userAgent
  return (
    <div css={root}>
      <div css={[keyVisual, mobileNone]}>
        <img
          src={keyvisualPc}
          alt=""
          width="1920"
          height="1105"
          css={keyVisualStyle}
        />
      </div>
      <div css={[keyVisual, pcNone]}>
        <img
          src={keyvisualMb}
          alt=""
          width="375"
          height="812"
        />
      </div>
      <div css={container}>
        <div css={logos}>
          <div>
            <h1 css={logo}>
              <img
                src={logoImage}
                alt="MtA creations"
                width="480"
                height="273"
              />
            </h1>
            <p css={copy}>Next Auth, Next Life.</p>
          </div>
        </div>
      </div>
      <p css={scroll}>
        Scroll<span css={arrow} area-hidden="true"></span>
        <span css={arrow} area-hidden="true"></span>
      </p>
    </div>
  )
}

export default KeyVisual
