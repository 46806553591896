import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import Title from './Title'

import Color from '../styles/Color'
import Size from '../styles/Size'
import Typography from '../styles/Typography'

import { max959, min960 } from '../styles/Mixin'

const root = css`
  position: relative;
  overflow: hidden;
  ${max959} {
    padding-top: ${Size(20)};
    padding-bottom: ${Size(20)};
  }
  ${min960} {
    padding-top: ${Size(40)};
    padding-bottom: ${Size(40)};
  }

  // &:after {
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-color: transparent transparent ${Color.Blue100} transparent;
  //   content: '';
  //   ${max959} {
  //     bottom: 0;
  //     left: 40%;
  //     border-width: 0 0 18.4vw 100vw;
  //   }
  //   ${min960} {
  //     bottom: 0;
  //     left: 40%;
  //     border-width: 0 0 18.4vw 100vw;
  //   }
  // }
`

const container = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 ${Size(4)};
  margin: auto;
  ${min960} {
    width: ${Size(240)};
  }
`

const contents = css``

const items = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: ${Size(18)} 0;
  list-style: none;
`

const item = css`
  width: 100%;
  & + & {
    margin-top: ${Size(4)};
  }
`

const itemTitle = css`
  ${Typography.Body3}
`

const itemText = css`
  ${Typography.Body1}
`

const Information = props => {
  const data = props.news
  return (
    <div css={root} id="information">
      <div css={container}>
        <div css={contents}>
          <Title text="Information" subTitle="お知らせ" />
          <ul css={items}>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li key={node.id} css={item}>
                <Link to={`/${node.frontmatter.path}`}>
                  <time css={itemTitle} datatime={node.frontmatter.date}>
                    {node.frontmatter.date} -{' '}
                  </time>
                  <p css={itemText}>{node.frontmatter.title}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Information
